<template>
  <SchedulerComponent />
</template>

<script>
import SchedulerComponent from '@/components/schedulerPush/SchedulerComponent.vue'

export default {
  components: {SchedulerComponent}
}
</script>

<style>

</style>
