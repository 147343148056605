import {
  DataTable,
  ItemsPerPageDropdown,
  Pagination
} from "v-datatable-light";
import {
  permissionModalPush
} from '@/assets/js/Global.js'
import SchedulerComponent from '@/components/schedulerPush/SchedulerComponent.vue'

export default {
  components: {
    DataTable,
    ItemsPerPageDropdown,
    Pagination,
    SchedulerComponent
  },
  data() {
    return {
      headerFields: [{
          name: "titulo",
          label: "Título",
          customElement: true

        },
        {
          name: "mensagem",
          label: "Conteúdo",
          customElement: true

        },
        {
          name: "periodicidade",
          format: val => {
            return this.periodicidade(val);
          },
          label: "Periodicidade",
          width: "200px"
        },
        {
          name: "faixa_horario",
          format: val => {
            return this.faixaHorario(val);
          },
          label: "Horário do envio",
          width: "400px"
        },
        {
          name: "condicao",
          format: val => {
            return this.condicao(val.condicao);
          },
          label: "Condição",
          width: "300px"
        },
        {
          name: "actions",
          label: "Ações",
          width: "107px",
          customElement: true
        }
      ],
      headerFieldsModal: [{
          name: "data_envio",
          format: val => {
            return new Date(val).toLocaleDateString();
          },
          label: "Data de envio",
          width: "150px"
        },
        {
          name: "nome",
          label: "Nome",
          customElement: true
        },
        {
          name: "recebida",
          format: val => {
            return val == 1 ? "Sim" : "Não";
          },
          label: "Recebido",
          width: "150px"
        },
        {
          name: "visualizada",
          format: val => {
            return val == 1 ? "Sim" : "Não";
          },
          label: "Aberto",
          width: "150px"
        },

      ],
      dataTable: require("../../../assets/js/DataTable")(),
      notificationModal: false,
      editSchedule: null,
      loading: {
        created: true,
        modal: true,
        tableModal: false
      }
    };
  },
  mounted() {
    $('#modalEdit').on("hidden.bs.modal", () => {
      this.editSchedule = null
    });
  },
  async created() {
    await this.createTable();
    this.dataTable.itemsPerPageModal = this.dataTable.itemsPerPage;
    this.dataTable.currentPageModal = this.dataTable.currentPage;
  },
  methods: {
    async createTable() {
      this.loading.created = true;

      let response = await this.DONUZ.triggers.getScheduledTriggersPush(this.dataTable
        .currentPage, this
        .dataTable.itemsPerPage);

      if (response.status == 200) {
        this.dataTable.table_rows = response.notificacoes;
        this.dataTable.totalItems = response.total;
      } else {
        this.$swal('Oops!', response.mensagem, 'error');
      }

      this.loading.created = false;
    },
    async deleteSchedule(props) {
      if (!permissionModalPush(this)) {
        return
      };

      this.$swal({
        title: "Importante",
        text: "Confirma essa exclusão?",
        type: "warning",
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: "Sim",
        cancelButtonText: "Cancelar"
      }).then(async data => {
        if (data.value) {

          let response = await this.DONUZ.triggers.deleteTrigger(props.rowData.id);

          if (response.status == 200) {
            this.$swal(
              "Sucesso", "Agendamento excluido com sucesso!", "success"
            ).then(r => location.reload());
          } else {
            this.$swal('Oops!', response.mensagem, 'error').then(r => location.reload());
          }
        }
      });
    },
    async notificationDetails(notification) {
      if (notification) {
        this.notificationModal = notification;
        this.loading.modal = true;
      } else {
        this.loading.tableModal = true
      }

      let response = await this.DONUZ.triggers.getScheduleDetails(this.notificationModal.id, this
        .dataTable.currentPageModal, this.dataTable.itemsPerPageModal);

      if (response.status == 200) {
        this.dataTable.table_rows_modal = response.notificacoes;
        this.dataTable.totalItemsModal = response.total;
      } else {
        this.$swal('Oops!', response.mensagem, 'error');
      }

      this.loading.modal = false;
      this.loading.tableModal = false;
    },
    changePage(currentPage) {
      this.dataTable.currentPage = currentPage;
      this.createTable();
    },
    updateItemsPerPage(itemsPerPage) {
      this.dataTable.itemsPerPage = parseInt(itemsPerPage);
      this.dataTable.currentPage = 1;
      this.createTable();
    },
    changePageModal(currentPage) {
      this.dataTable.currentPageModal = currentPage;
      this.notificationDetails();
    },
    updateItemsPerPageModal(itemsPerPage) {
      this.dataTable.itemsPerPageModal = parseInt(itemsPerPage);
      this.dataTable.currentPageModal = 1;
      this.notificationDetails();
    },
    condicao(val) {
      switch (val) {
        case "1":
          return "Aniversariante";
        case "2":
          return "Clientes Inativos";
        case "3":
          return "Todos os clientes";
      }
    },
    faixaHorario(val) {
      switch (val) {
        case "1":
          return "8h às 11h";
        case "2":
          return "11h às 14h";
        case "3":
          return "14h às 17h";
        case "4":
          return "17h às 20h";
        case "5":
          return "20h às 23h";
      }
    },
    diaSemana(val) {
      switch (val) {
        case "1":
          return "Segunda";
        case "2":
          return "Terça";
        case "3":
          return "Quarta";
        case "4":
          return "Quinta";
        case "5":
          return "Sexta";
        case "6":
          return "Sábado";
        case "7":
          return "Domingo";
      }
    },
    periodicidade(val) {
      switch (val) {
        case "1":
          return "Toda semana";
        case "2":
          return "Dia específico";
        case "3":
          return "Todos os dias";
      }
    },
  },
  computed: {
    dataBR() {
      return new Date(this.notificationModal.execucao + ' 00:00:00').toLocaleDateString();
    }
  }
};
