module.exports = function (){
  return {
    "listItemsPerPage": [10, 20, 50],
    "itemsPerPage": 10,
    "currentPage": 1,
    "totalItems": 1,
    "table_rows": [],
    "tableHeight": "height",
    "defaultColumnWidth": "551px",
    "datatableCss": {
      "table": "table table-bordered table-hover table-striped table-center",
      "thWrapper": "th-wrapper",
      "arrowsWrapper": "arrows-wrapper",
      "arrowUp": "arrow up",
      "arrowDown": "arrow down",
      "footer": "footer"
    },
    "paginationCss": {
      "paginationItem": "pagination-item",
      "moveFirstPage": "move-first-page",
      "movePreviousPage": "move-previous-page",
      "moveNextPage": "move-next-page",
      "moveLastPage": "move-last-page",
      "pageBtn": "page-btn"
    },
    "itemsPerPageCss": {
      "select": "item-per-page-dropdown"
    }
  }
}
