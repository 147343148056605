import Vue from 'vue';
import VueRouter from 'vue-router';
import AvailableSoon from '@/views/availableSoon/AvailableSoon.vue';
import CustomersPush from '@/views/pushNotification/customers/Customers.vue';
import SchedulerPush from '@/views/pushNotification/scheduler/Scheduler.vue';
import SchedulesPush from '@/views/pushNotification/schedules/Schedules.vue';
import DashboardPush from '@/views/pushNotification/dashboard/DashboardPush.vue';
import CustomersWpp from '@/views/whatsapp/customers/CustomersWpp.vue';
import SchedulerWpp from '@/views/whatsapp/scheduler/SchedulerWpp.vue';
import SchedulesWpp from '@/views/whatsapp/schedules/SchedulesWpp.vue';
import DashboardWpp from '@/views/whatsapp/dashboard/DashboardWpp.vue';
import Login from '@/views/login/Login.vue';


Vue.use(VueRouter);

const routes = [
  {
    path: '/login/:id/:user',
    name: 'loginCrm',
    component: Login,
  },
  {
    path: '/availableSoon',
    name: 'AvailableSoon',
    component: AvailableSoon,
  },
  {
    path: '/pushNotification/dashboard',
    name: 'Dashboard',
    component: DashboardPush,
  },
  {
    path: '/pushNotification/customers',
    name: 'Customers',
    component: CustomersPush,
  },
  {
    path: '/pushNotification/scheduler',
    name: 'Scheduler',
    component: SchedulerPush,
  },
  {
    path: '/pushNotification/collectiveShipping',
    name: 'CollectiveShipping',
    component: {
      ...SchedulerPush
    },
  },
  {
    path: '/pushNotification/schedules',
    name: 'Schedules',
    component: SchedulesPush,
  },
  {
    path: '/whatsapp/dashboard',
    name: 'DashboardWpp',
    component: DashboardWpp,
  },
  {
    path: '/whatsapp/customers',
    name: 'CustomersWpp',
    component: CustomersWpp,
  },
  {
    path: '/whatsapp/scheduler',
    name: 'SchedulerWpp',
    component: SchedulerWpp,
  },
  {
    path: '/whatsapp/collectiveShipping',
    name: 'CollectiveShippingWpp',
    component: {
      ...SchedulerWpp
    },
  },
  {
    path: '/whatsapp/schedules',
    name: 'SchedulesWpp',
    component: SchedulesWpp,
  },
];

import {publicPath} from '../../vue.config'

const router = new VueRouter({
  base: publicPath,
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  // if (to.path.includes('whatsapp')) {
  //   next({
  //     path: '/availableSoon'
  //   })
  //   return;
  // }
  next();
});

export default router;
