import Vue from 'vue';
import App from './App.vue';
import Login from './views/login/Login.vue';
import router from './router';
import VueSweetalert2 from 'vue-sweetalert2';
import {Donuz} from "@/donuz-api/Donuz.js";
import './registerServiceWorker';
import 'sweetalert2/dist/sweetalert2.min.css';
import "./assets/scss/App.scss"

Vue.config.productionTip = false;

import {publicPath} from '../vue.config'


Vue.use(VueSweetalert2);

Vue.mixin({
  data() {
    return {
      DONUZ : Donuz,
      ESTABELISHMENT: JSON.parse(sessionStorage.getItem('data_estabelishment')),
      PUBLIC_PATH: publicPath
    };
  },
})

new Vue({
  router,
  render: h => h(sessionStorage.getItem('data_estabelishment') ? App : Login),
}).$mount('#app');
