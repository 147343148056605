export default {
  props: {
    notify: Object,
    scheduler: Boolean,
    currentClient: Object
  },
  data() {
    return {
      loading: true,
      wppMessages: false,
      pesquisas: {}
    };
  },
  async created() {
    let response = await this.DONUZ.whatsapp.getMessages();
    if (response.status == 200) {
      this.wppMessages = response.mensagens;
      if (!this.scheduler) {
        for (let msg of this.wppMessages) {
          msg.mensagem = msg.mensagem.replace(
            "{nome_membro}",
            `${this.currentClient.primeiro_nome}`
            // `${this.currentClient.primeiro_nome}${this.currentClient.segundo_nome || ''}`);
          );
        }
      }
    } else {
      this.$swal("Oops!", response.mensagem, "error");
    }

    response = await this.DONUZ.whatsapp.getResearches();
    if (response.status == 200) {
      this.pesquisas = response.pesquisas;
    } else {
      this.$swal("Oops!", response.mensagem, "error");
    }
    this.loading = false;
  },
  methods: {
    toHTML(msg) {
      msg.replace(/\\n/g, "<br/>");
      return msg.replace('{link_pesquisa}', '');
    }
  }
};
