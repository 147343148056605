export const BASE_URL = 'https://app.donuz.co/api/';
export const TOKEN = sessionStorage.getItem('token');
export const ESTABELECIMENTO = sessionStorage.getItem('estabelecimento');

const headers = {
  'estabelecimento': ESTABELECIMENTO ? ESTABELECIMENTO : '',
  'token': TOKEN ? TOKEN : '410d4633ae5741efb2adf25de2c1dbb0',
  'Content-type': 'application/json'
}

export async function get(url) {
  let obj = await fetch(BASE_URL + url, {
      headers: headers,
      method: 'GET'
    })
    .then((data) => (data.json())).catch((data) => console.log(data));
  return obj;
}
export async function deleteRequest(url) {
  let obj = await fetch(BASE_URL + url, {
      headers: headers,
      method: 'DELETE'
    })
    .then((data) => (data.json())).catch((data) => console.log(data));
  return obj;
}
export async function post(url, parameter, stringify = true) {
  let obj = await fetch(BASE_URL + url, {
    method: 'POST',
    headers: headers,
    body: stringify ? JSON.stringify(parameter) : parameter,
    json: true,
  }).then((data) => data.json()).catch((data) => console.log(data));
  return obj;
}
export async function put(url, parameter) {
  let obj = await fetch(BASE_URL + url, {
    method: 'PUT',
    headers: headers,
    body: JSON.stringify(parameter),
    json: true
  }).then((data) => (data.json())).catch((data) => console.log(data));
  return obj;
}
