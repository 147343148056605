import {
  post  , get
} from '../requisitions/Requisitions';

export var notification = {
  getDetails() {
    return get('notification/count')
  },
  sendNotification(data) {
    data = {... data}
    validateImage(data)
    return post('notification/send', data);
  },
  sendNotificationDinamically(data) {
    data = {... data}
    validateImage(data)
    return post('notification/dinamically/send', data);
  },
}

function validateImage(data) {
  if (data.link_imagem && !data.link_imagem.includes("https")) {
    data.link_imagem = "";
  }
}
