import Doughnut from "@/components/charts/Doughnut.js";

export default {
  props: {
    type: String
  },
  components: {
    Doughnut
  },
  data() {
    return {
      data: {},
      loading: true,
      datacollection: {
        responsive: true,
        labels: ["Abertos", "Não Abertos"],
        datasets: [
          {
            backgroundColor: ["#2b91f1", "#96c6f3"]
          }
        ]
      },
      titles: {
        push: { title: "Push notification", titleCard: "NOTIFICAÇÕES" },
        wpp: { title: "Whatsapp", titleCard: "MENSAGENS" }
      }
    };
  },
  async created() {
    let response;
    switch (this.type) {
      case "wpp":
        this.data.titles = this.titles.wpp;
        response = await this.DONUZ.whatsapp.getDetails();
        this.data.details = response.quantidades;
        break;
      case "push":
        this.data.titles = this.titles.push;
        response = await this.DONUZ.notification.getDetails();
        this.data.details = response.quantidades;
        break;
    }

    this.datacollection.datasets[0].data = [
      this.data.details.visualizado,
      this.data.details.recebidas - this.data.details.visualizado
    ];
    this.loading = false;
  },
  computed: {
    viewd() {
      return this.datacollection.datasets[0].data[0];
    },
    noViewd() {
      return this.datacollection.datasets[0].data[1];
    },
    total() {
      return this.viewd + this.noViewd;
    },
    viewdPercent() {
      return ((this.viewd / this.total) * 100).toFixed(2);
    },
    noViewdPercent() {
      return ((this.noViewd / this.total) * 100).toFixed(2);
    }
  }
};