export default {
    props: {
      elChange: Object
    },
    data() {
      return {
        loading: false
      };
    },
    methods: {
      async onFileChange() {
        if (!this.$refs.img.files[0]) {
          return;
        }
        this.loading = true;
        let response = await this.DONUZ.image.upload(this.$refs.img);
        if (response.status == 200) {
          this.elChange.link_imagem = response.link;
        } else {
          this.$swal("Oops!", response.mensagem, "error");
        }
        this.loading = false;
      }
    }
  };