  <template>
  <div>
    <div class="path">
      <small>WHATSAPP</small>
      <span>></span>
      <small>DASHBOARD</small>
      <h4>Dashboard Whatsapp</h4>
    </div>
    <Dashboard type="wpp" />
  </div>
</template>

<script>
import Dashboard from "@/components/dashboard/Dashboard.vue";

export default {
  components: {
    Dashboard
  }
};
</script>
