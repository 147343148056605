import ImageUpload from '@/components/imageUpload/ImageUpload.vue'
import InputVariables from '@/components/inputVariables/InputVariables.vue'
import {permissionModalPush} from '@/assets/js/Global.js'
const img = require("../../assets/img/icons/img-icon.png");

export default {
  components: {
    ImageUpload,
    InputVariables
  },
  props: {
    editSchedule: Object
  },
  data() {
    return {
      schedule: {
        condicao: {},
        link_imagem: img,
        mensagem: "",
        push: true,
        whatsapp: false
      },
      loading: {
        buttonImg: false,
        scheduler: false,
        sendNow: false
      }
    };
  },
  created() {
    if (this.editSchedule) {
      this.schedule = {
        ...this.editSchedule
      };
      if (!this.schedule.link_imagem.includes("https")) this.schedule.link_imagem = img
    }
  },
  methods: {
    async sendNow() {
      if(!permissionModalPush(this)){return};

      this.$swal({
        icon: "question",
        title: "Confirma o envio?",
        confirmButtonText: "Sim",
        cancelButtonText: "Cancelar",
        showCloseButton: true,
        showCancelButton: true
      }).then(async data => {
        if (data.value) {
          this.loading.sendNow = true;

          let response = await this.DONUZ.notification.sendNotificationDinamically(this
            .schedule);

          if (response.status == 200) {
            this.$swal(
              "Sucesso", "Estamos processando o envio!", "success"
            ).then(r => location.reload());
          } else {
            this.$swal('Oops!', response.mensagem, 'error').then(r => location.reload());
          }

          this.loading.sendNow = false;
        }
      });
    },
    async scheduler() {
      if(!permissionModalPush(this)){return};

      if (!this.dateValidate()) {
        return;
      }

      this.$swal({
        icon: "question",
        title: this.editSchedule ? "Confirma a alteração?" : "Confirma o agendamento?",
        confirmButtonText: "Sim",
        cancelButtonText: "Cancelar",
        showCloseButton: true,
        showCancelButton: true
      }).then(async data => {
        if (data.value) {
          this.loading.scheduler = true;

          let response;
          if (this.editSchedule) {
            response = await this.DONUZ.triggers.updateTrigger(this.schedule);
          } else {
            response = await this.DONUZ.triggers.scheduleTrigger(this.schedule);
          }

          if (response.status == 200 || response.status == 201) {
            this.$swal("Sucesso!", response.mensagem, "success").then(r => {
              $("#modalEdit").modal("hide");
              this.schedule.link_imagem = img;
              location.reload();
            });
          } else {
            this.$swal('Oops!', response.mensagem, 'error');
          }

          this.loading.scheduler = false;
        }
      })

    },
    dateValidate() {
      if (
        this.schedule.periodicidade == 2 &&
        new Date(this.schedule.execucao + "T00:00:00") <= new Date()
      ) {
        this.$swal(
          "Data invalida!",
          "A data inserida não pode ser menor ou igual ao dia atual!",
          "warning"
        );
        return false;
      }
      return true;
    },
  }
};
