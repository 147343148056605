  function permissionModalPush(context) {
      if (!context.ESTABELISHMENT.permissoes.push_notification) {
        $(".modal").modal("hide");
        $("#ModalUpgradePush").modal("show");
        return false;
      }
      return true;
    }
  function permissionModalWpp(context) {
      if (!context.ESTABELISHMENT.permissoes.whatsapp) {
        $(".modal").modal("hide");
        $("#ModalUpgradeWpp").modal("show");
        return false;
      }
      return true;
    }

export {permissionModalPush,permissionModalWpp};
