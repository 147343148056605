
export default {
  data() {
    return {
      menuOpen: false
    };
  },
  mounted() {
    if (window.innerWidth < 1190) {
      this.openCloseMenu()
    }
  },
  methods: {
    openCloseMenu(action) {
      switch (action) {
        case "open":
          barRigth.classList.remove("d-none");
          bodyApp.style.marginLeft = "291px";
          return;
        case "ul":
          if (window.innerWidth < 1190) {
            menu.classList.add("d-none");
            bodyApp.style.zIndex = "auto";
          }
          break;
        default:
          if (window.innerWidth >= 1190) {
            barRigth.classList.add("d-none");
            bodyApp.style.marginLeft = "55px";
          } else {
            menu.classList.add("d-none");
            bodyApp.style.zIndex = "auto";
          }
          break;
      }
    },
    logout(){
      sessionStorage.clear();
      location.href = this.PUBLIC_PATH;
    }
  }
};
