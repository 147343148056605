export default {
  methods: {
    openMenu() {
      menu.classList.remove("d-none");
      barRigth.classList.remove("d-none");
      bodyApp.style.zIndex = -2;
    },
    // logout(){
    //   sessionStorage.clear();
    //   location.href = '/';
    // }
  },
  
};
