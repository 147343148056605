import {
    post,
    deleteRequest
} from '../requisitions/Requisitions';

export var devices = {
    getDevicesByEstabelishment(data) {
        return post(`devices/search`, data);
    },
    deleteDevice(playerId) {
        return deleteRequest(`device/${playerId}`);
    }
}