import {
  DataTable,
  ItemsPerPageDropdown,
  Pagination
} from 'v-datatable-light';

import ImageUpload from '@/components/imageUpload/ImageUpload.vue'
import InputVariables from '@/components/inputVariables/InputVariables.vue'
import {
  permissionModalPush
} from '@/assets/js/Global.js'
const imgLink = require("@/assets/img/icons/img-icon.png");

export default {
  components: {
    DataTable,
    ItemsPerPageDropdown,
    Pagination,
    ImageUpload,
    InputVariables
  },
  data() {
    return {
      dataTable: require('@/assets/js/DataTable')(),
      headerFields: [{
          name: 'nome',
          label: 'Usuarios do Aplicativo',
          width: "90%",
          customElement: true
        },
        {
          name: 'act',
          label: 'Ações',
          width: "10%",
          customElement: true,
        }
      ],
      notify: {
        link_imagem: imgLink
      },
      estabelecimento: {},
      loading: {
        buttonImg: false,
        send: false,
        created: true,
        dataTable: false
      },
    }
  },
  async created() {
    this.loading.created = true;
    this.createTable();
    this.loading.created = false;
  },
  methods: {
    async createTable() {
      this.loading.dataTable = true;

      let response = await this.DONUZ.devices.getDevicesByEstabelishment({
        nome: this.dataTable.search,
        page: this.dataTable
          .currentPage,
        per_page: this.dataTable.itemsPerPage
      });

      if (response.status == 200) {
        this.dataTable.table_rows = response.clientes;
        this.dataTable.totalItems = response.total;
      } else {
        this.$swal('Oops!',
          response.mensagem,
          'error').then(r => location.reload())
      }
      this.loading.dataTable = false;
    },
    async deletePlayerid(props) {
      if (!permissionModalPush(this)) {
        return
      };
      this.$swal({
        title: 'Importante',
        text: 'Deseja remover este usuário de push notification?',
        icon: 'warning',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Cancelar',
        showCloseButton: true,
        showCancelButton: true,
      }).then(async (data) => {
        if (data.value) {

          let response = await this.DONUZ.devices.deleteDevice(props.rowData.player_id);

          if (response.status == 200) {
            this.$swal('Sucesso!', 'Ação realizada com sucesso!', 'success');
            this.createTable();
          } else {
            this.$swal('Oops!', response.mensagem, 'error').then(r => location.reload())
          }
        }

      });
    },
    async send() {
      if (!permissionModalPush(this)) {
        return
      };

      this.$swal({
        icon: 'question',
        title: 'Confirma o envio?',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Cancelar',
        showCloseButton: true,
        showCancelButton: true,
      }).then(async (data) => {
        if (data.value) {

          this.loading.send = true
          let response = await this.DONUZ.notification.sendNotification(this.notify);
          if (response.status == 200) {
            this.$swal('Sucesso', 'Estamos processando o envio!', 'success').then(r =>
              location.reload());
            this.notify = {
              link_imagem: imgLink
            }
            $('#modalSend').modal('hide');

          } else {
            this.$swal('Oops!', response.mensagem, 'error').then(r => location.reload())
          }
        }
        this.loading.send = false;
      });
    },
    async onFileChange() {
      if (!this.$refs.img.files[0]) {
        return
      }
      this.loading.buttonImg = true;
      let response = await this.DONUZ.image.upload(this.$refs.img);
      if (response.status == 200) {
        this.notify.link_imagem = response.link
      } else {
        this.$swal('Oops!', response.mensagem, 'error').then(r => location.reload())
      }
      this.loading.buttonImg = false;
    },
    changePage(currentPage) {
      this.dataTable.currentPage = currentPage;
      this.createTable();
    },
    updateItemsPerPage(itemsPerPage) {
      this.dataTable.itemsPerPage = parseInt(itemsPerPage);
      this.dataTable.currentPage = 1;
      this.createTable();
    },
  }
}
