export default {
    props: {
      type: String
    },
    data() {
      return {
        current: {},
        push: {
          title: "Push Notification",
          msg:
            "O envio de push notification esta indisponível no seu plano atual. Adquira um pacote e obtenha:",
          listBenefits: [
            "Notificação para clientes especificos",
            "Notificação para grupos dinâmicos",
            "Agendamento de notificação"
          ]
        },
        whatsapp: {
          title: "Whatsapp",
          msg:
            "O envio de whatsapp esta indisponível no seu plano atual. Adquira um pacote e obtenha:",
          listBenefits: [
            "Whatsapp para clientes especificos",
            "Whatsapp para grupos dinâmicos",
            "Agendamento de whatsapp"
          ]
        }
      };
    },
    created() {
      switch (this.type) {
        case "whatsapp":
          this.current = this.whatsapp;
          break;
        case "push":
          this.current = this.push;
          break;
      }
      this.$forceUpdate();
    }
  };