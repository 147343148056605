import {
  get,
  post,
} from '../requisitions/Requisitions';

export var whatsapp = {
  getDetails() {
    return get('whatsapp/count')
  },
  getMessages() {
    return get('whatsapp/messages')
  },
  getResearches() {
    return get('searchs')
  },
  sendWhatsappDinamically(data) {
    delete data.mensagem
    return post('whatsapp/dinamically/send', data)
  },
  sendWhatsapp(data) {
    delete data.mensagem
    return post('whatsapp/send', data);
  }
}
