// import { categories } from './models/Categoria';
// import { computableCode } from './models/CodigoPontuavel';
import { clients } from './models/Cliente';
// import { settings } from './models/Configuracao';
// import { mails } from './models/Emails';
import { estabelishment } from './models/Estabelecimento';
import { notification } from './models/Notification';
import { image } from './models/Imagem';
import { triggers } from './models/Gatilhos';
import { whatsapp } from './models/Whatsapp';
// import { branch } from './models/Filial';
// import { points } from './models/Ponto';
// import { prizes } from './models/Premio';
// import { rescues } from './models/Resgate';
// import { balance } from './models/Saldo';
// import { voucher } from './models/Voucher';
// import { gifts } from './models/Presentes';
import { devices } from './models/Dispositivos';
import { permission } from './models/Permission';
export var Donuz = {
    // category: categories,
    // customer: clients,
    // scoringCode: computableCode,
    // settings: settings,
    // mail: mails,
    // store: estabelishment,
    // branch: branch,
    // point: points,
    // reward: prizes,
    // redeemption: rescues,
    // balance: balance,
    // voucher:voucher,
    // gifts: gifts,
    estabelishment: estabelishment, 
    // prizes: prizes,
    devices:devices,
    notification:notification,
    image:image,
    triggers:triggers,
    clients:clients,
    whatsapp:whatsapp,
    permission:permission,
}