import {
  BASE_URL, ESTABELECIMENTO, TOKEN
} from '../requisitions/Requisitions';

export var image = {
  async upload(el) {

    let arq = new FormData();
    arq.append("upload", el.files[0]);

    let obj = await fetch(BASE_URL + 'upload', {
        method: "POST",
        body: arq,
        headers: {
            token: TOKEN,
            estabelecimento: ESTABELECIMENTO
        }
      })
      .then(data => data.json())

    return obj;
  },
}
