export default {
  data() {
    return {
      dataLogin: {},
      resp: {},
      loading: false
    };
  },
  async created() {
    if(this.$route.params.id!=undefined && this.$route.params.user!=undefined){
      this.dataLogin= {id_admin: this.$route.params.id,
        id_user:this.$route.params.user}
      this.login();  
    }
  },
  methods: {
    async login() {
      this.loading = true;
      this.resp = await this.DONUZ.estabelishment.singIn(this.dataLogin);

      if (this.resp.status == 200) {
        this.resp.estabelecimento.permissoes = this.resp.permissoes
        sessionStorage.setItem('date', new Date().getDay())
        sessionStorage.setItem('estabelecimento', this.resp.estabelecimento.id_admin)
        sessionStorage.setItem('token', this.resp.token)
        sessionStorage.setItem('data_estabelishment', JSON.stringify(this.resp.estabelecimento))
        // this.$router.push('/pushNotification/dashboard');
        location.href =  this.PUBLIC_PATH + 'pushNotification/dashboard'
      } else {
        this.resp.error = this.resp.mensagem;
        this.loading = false;
      }
    }
  }
};
