import TableMessagesWpp from '@/components/whatsapp/table_messages/TableMessagesWpp.vue'
import celular from "@/assets/img/wpp.png";
import {permissionModalWpp} from '@/assets/js/Global.js'

export default {
  components: {
    TableMessagesWpp
  },
  props: {
    editSchedule: Object
  },
  data() {
    return {
      schedule: {
        condicao: {},
        push: false,
        whatsapp: true
      },
      loading: {
        buttonImg: false,
        scheduler: false,
        sendNow: false
      }
    };
  },
  created() {
    if (this.editSchedule) {
      this.schedule = {
        ...this.editSchedule
      };
    }
  },
  methods: {
    async sendNow() {
      if(!permissionModalWpp(this)){return};

      this.$swal({
        title: "Confirma o envio da seguinte mensagem?",
        confirmButtonText: "Sim",
        cancelButtonText: "Cancelar",
        showCloseButton: true,
        showCancelButton: true,
        html: `<div class="msgPrevia"><img src="${celular}"/><p>${this.schedule.mensagem}</p></div>`
      }).then(async data => {
        if (data.value) {
          this.loading.sendNow = true;

          let response = await this.DONUZ.whatsapp.sendWhatsappDinamically(this.schedule);

          if (response.status == 200) {
            this.$swal(
              "Sucesso", "Estamos processando o envio!", "success"
            ).then(r => location.reload());
          } else {
            this.$swal('Oops!', response.mensagem, 'error');
          }

          this.loading.sendNow = false;
        }
      });
    },
    async scheduler() {
      if(!permissionModalWpp(this)){return};

      if (!this.dateValidate()) {
        return;
      }

      this.$swal({
        title: this.editSchedule ? "Confirma a alteração?" : "Confirma o agendamento da seguinte mensagem?",
        confirmButtonText: "Sim",
        cancelButtonText: "Cancelar",
        showCloseButton: true,
        showCancelButton: true,
        html: `<div class="msgPrevia"><img src="${celular}"/><p>${this.schedule.mensagem}</p></div>`

      }).then(async data => {
        if (data.value) {
          this.loading.scheduler = true;

          let response;
          if (this.editSchedule) {
            response = await this.DONUZ.triggers.updateTrigger(this.schedule);
          } else {
            response = await this.DONUZ.triggers.scheduleTrigger(this.schedule);
          }

          if (response.status == 200 || response.status == 201) {
            this.$swal("Sucesso", "Ação realizada com sucesso!", "success").then(r => {
              $("#modalAgendar").modal("hide");
              location.reload();
            });
          } else {
            this.$swal('Oops!', response.mensagem, 'error');
          }

          this.loading.scheduler = false;
        }
      })

    },
    dateValidate() {
      if (
        this.schedule.periodicidade == 2 &&
        new Date(this.schedule.execucao + "T00:00:00") <= new Date()
      ) {
        this.$swal(
          "Data invalida!",
          "A data inserida não pode ser menor ou igual ao dia atual!",
          "warning"
        );
        return false;
      }
      return true;
    },
  }
};
