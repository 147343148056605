import {
  get,
  post,
  put,
  deleteRequest
} from '../requisitions/Requisitions';

export var triggers = {
  getScheduledTriggersPush(page, per_page) {
    return get(`push/triggers?page=${page}&per_page=${per_page}`);
  },
  getScheduledTriggersWpp(page, per_page) {
    return get(`whatsapp/triggers?page=${page}&per_page=${per_page}`);
  },
  getScheduleDetails(id, page, per_page) {
    return get(`triggers/${id}/results?page=${page}&per_page=${per_page}`);
  },
  scheduleTrigger(data) {
    validations(data)
    return post('triggers/schedule', data);
  },
  updateTrigger(data) {
    validations(data)
    return put('trigger/update', data);
  },
  deleteTrigger(id) {
    return deleteRequest(`trigger/${id}`);
  }

}

function validations(data) {
  if (data.link_imagem && !data.link_imagem.includes("https")) {
    data.link_imagem = "";
  }
  if(data.whatsapp){
    delete data.mensagem
    delete data.conteudo
    delete data.link
    delete data.link_pesquisa
  }
}