import {
    get,
  } from '../requisitions/Requisitions';
  
  export var permission = {
    getPermission(id) {
      return get(`recurso/permissao/${id}'`);
    },
  }
  
