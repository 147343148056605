import {
  DataTable,
  ItemsPerPageDropdown,
  Pagination
} from 'v-datatable-light';
import TableMessagesWpp from '@/components/whatsapp/table_messages/TableMessagesWpp.vue'
import celular from "@/assets/img/wpp.png";
import {
  permissionModalWpp
} from '@/assets/js/Global.js'

export default {
  components: {
    DataTable,
    ItemsPerPageDropdown,
    Pagination,
    TableMessagesWpp
  },
  data() {
    return {
      dataTable: require('@/assets/js/DataTable')(),
      headerFields: [{
          name: 'nome',
          label: 'Nome',
          width: "50%",
          customElement: true
        },
        {
          name: 'celular',
          label: 'Celular',
          width: "15%",
        },
        {
          name: 'act',
          label: 'Ações',
          width: "10%",
          customElement: true,
        }
      ],
      notify: {},
      currentClient: false,
      wppMessages: false,
      loading: {
        modalSend: true,
        send: false,
        created: true,
        dataTable: false
      }
    }
  },
  updated() {
    $('#modalSendWpp').on('hidden.bs.modal', function (e) {
      if ($('#ModalUpgradeWpp').css('display') == 'none') {
        location.reload()
      }

    })
  },
  async created() {
    this.createTable();
    this.loading.created = false;
  },
  methods: {
    async createTable() {
      this.loading.dataTable = true;

      let response = await this.DONUZ.clients.getClientsWithPhone({
        filtro: this.dataTable.search,
        page: this.dataTable
          .currentPage,
        per_page: this.dataTable.itemsPerPage
      });

      if (response.status == 200) {
        this.dataTable.table_rows = response.clientes;
        this.dataTable.totalItems = response.total;
      } else {
        this.$swal('Oops!',
          response.mensagem,
          'error').then(r => location.reload())
      }
      this.loading.dataTable = false;
    },
    async getMessages(client) {
      this.notify.id_cliente = client.id_cliente
      this.currentClient = client;
    },
    sendWpp() {
      if (!permissionModalWpp(this)) {
        return
      };

      this.$swal({
        title: 'Confirma o envio da seguinte mensagem?',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Cancelar',
        showCloseButton: true,
        showCancelButton: true,
        showLoaderOnConfirm: true,
        html: `<div class="msgPrevia"><img src="${celular}"/><p>${this.notify.mensagem}</p></div>`
      }).then(async r => {
        if (r.value) {
          this.loading.send = true

          let response = await this.DONUZ.whatsapp.sendWhatsapp(this.notify);

          if (response.status == 200) {
            this.$swal('Sucesso', 'A mensagem foi enviada!', 'success').then(r => {
              if (r.value) {
                $('#modalSendWpp').modal('hide');
              }
            });

          } else {
            let error;
            switch (response.mensagem ? response.mensagem : response.erros.code) {
              case "1002":
                error =
                  'Esse contato não tem whatsapp ou o numero de telefone está incorreto! Observação: os telefones deverão ser cadastrados com DDD, caso contrario não será possivel enviar mensagens via whatsapp.'
                break;
              case "1103":
                error =
                  'Ocorreu um problema com o template da menssagem, entre em contato com o suporte.'
                break;
              default:
                error = response.mensagem
                break;
            }
            this.$swal('Oops!', error, 'error').then(r => {
              if (r.value) {
                $('#modalSendWpp').modal('hide').then(r => location.reload());
              }
            });
          }
        }
      });
    },
    changePage(currentPage) {
      this.dataTable.currentPage = currentPage;
      this.createTable();
    },
    updateItemsPerPage(itemsPerPage) {
      this.dataTable.itemsPerPage = parseInt(itemsPerPage);
      this.dataTable.currentPage = 1;
      this.createTable();
    },

  }
}
