<template>
    <SchedulerWhatsapp/>
</template>

<script>
import SchedulerWhatsapp from '@/components/whatsapp/schedulerWhatsapp/SchedulerWppComponent.vue'
export default {
  components:{SchedulerWhatsapp}
}
</script>
