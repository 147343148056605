<template>
  <div class="form-group">
    <label>{{label}}</label>
    <textarea
      v-if="type == 'text-area'"
      :id="id"
      rows="3"
      v-model="data.mensagem"
      class="form-control"
      :placeholder="placeholder"
      required
    ></textarea>

    <input v-else :id="id" v-model="data.titulo" :placeholder="placeholder" class="form-control" type="text" required />

    <div
      class="btn-group dropup mt-1"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <button
        class="btn btn-outline-primary btn-sm dropdown-toggle"
        type="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >Inserir variáveis</button>

      <div class="dropdown-menu">
        <a href="#" class="dropdown-item" @click="typeInTextarea('{nome_membro}')">Nome do cliente</a>
        <a
          href="#"
          class="dropdown-item"
          @click="typeInTextarea('{nome_admin}')"
        >Nome do estabelecimento</a>
        <a
          href="#"
          class="dropdown-item"
          @click="typeInTextarea('{codigo_membro}')"
        >Código do cliente</a>
        <a href="#" class="dropdown-item" @click="typeInTextarea('{saldo_membro}')">Saldo de pontos</a>
        <a href="#" class="dropdown-item" @click="typeInTextarea('{cpf_membro}')">CPF do cliente</a>
        <a
          href="#"
          class="dropdown-item"
          @click="typeInTextarea('{segundo_nome_membro}')"
        >Sobrenome do cliente</a>
        <a
          href="#"
          class="dropdown-item"
          @click="typeInTextarea('{data_nascimento_membro}')"
        >Data de nascimento do cliente</a>
        <a href="#" class="dropdown-item" @click="typeInTextarea('{sexo_membro}')">Sexo do cliente</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: this.prop
    };
  },
  props: {
    prop: Object,
    type: String,
    placeholder: String,
    label: String,
    id: String
  },
  methods: {
    typeInTextarea(newText) {
      var el = document.getElementById(this.id);
      var start = el.selectionStart;
      var before = el.value.substring(0, start);
      var after = el.value.substring(el.selectionEnd, el.length);
      el.value = before + newText + after;
      this.type == 'text-area'? this.data.mensagem = el.value : this.data.titulo = el.value;
      el.selectionStart = el.selectionEnd = start + newText.length;
      el.focus();
    }
  }
};
</script>

<style>
</style>