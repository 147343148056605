<template>
  <div id="app" class="row">
    <Menu />
    <div class="col" id="bodyApp">
      <Navtop />
      <div class="m-4">
        <router-view />
      </div>
    </div>
    <ModalUpgrade id="ModalUpgradePush" type="push" />
    <ModalUpgrade id="ModalUpgradeWpp" type="whatsapp" />
  </div>
</template>

<script>
$(function() {
  $('[data-toggle="tooltip"]').tooltip();
  $('[data-toggle="tooltip"]').click(function() {
    $('[data-toggle="tooltip"]').tooltip("hide");
  });
});

import Menu from "@/components/menu/Menu.vue";
import Navtop from "@/components/navtop/Navtop.vue";
import ModalUpgrade from "@/components/modalUpgrade/ModalUpgrade.vue";
import {permissionModalPush,permissionModalWpp} from '@/assets/js/Global.js'

export default {
  components: { Menu, 
  Navtop, 
  ModalUpgrade },

  updated() {
    // switch (this.$route.path.split("/")[1]) {
    //   case "pushNotification":
    //     permissionModalPush(this);
    //     break;
    //   case "whatsapp":
    //     console.log("asd")
    //     permissionModalWpp(this);
    //     break;
    // }
  },
  async mounted() {
   if(sessionStorage.getItem('date') < new Date().getDay()){
     sessionStorage.clear()
   }
  }
};
</script>

<style lang="scss" scoped>
#bodyApp {
  @media (min-width: 1190px) {
    z-index: auto !important;
    margin-left: 291px;
  }
  @media (max-width: 1190px) {
    z-index: -2;
    margin-left: 0 !important;
  }
}
</style>

